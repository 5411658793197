<template>
	<div>
		<!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel start here -->
		<panel title="FORM INPUT STATUS REGISTRASI" class="panel panel-danger">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> FORM INPUT STATUS REGISTRASI</h6>
            </template> -->
            <div class="pl-4 py-2 border rounded shadow-sm">
				<b-button variant="primary" class="px-4" to="/admin/management/status-registrasi/list" pill>
                    <i class="fa fa-arrow-circle-left pr-2"></i> 
                    Kembali
                </b-button>
			</div>
			<div class="py-3">
                <b-card class="shadow-sm">
                        <b-form @submit.prevent="store" @keydown="form.onKeydown($event)" autocomplete="off">
                            <!-- <input type="hidden" name="_token" :value="csrf"> -->
                            <b-alert v-if="message" variant="danger" show>Data yang anda input tidak valid.</b-alert>
                            <h3>STATUS REGISTRASI</h3>
                            <b-table-simple striped>
                                <b-tbody>
                                    <b-tr>
                                        <b-td class="font-weight-bold" width="300px">Nomor Urut <span class="text-danger">*</span></b-td>
                                        <b-td>
                                            <b-row>
                                                <b-col sm="6">
                                                    <input type="text" v-model="form.no_urut" :class="{ 'form-control': true, 'is-invalid': form.errors.has('no_urut')}" placeholder="Masukkan Kode"/>
                                                </b-col>
                                            </b-row>
                                            <em v-if="form.errors.has('no_urut')" class="form-text text-danger">{{errors.no_urut[0]}}</em>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="font-weight-bold" width="300px">Status Registrasi <span class="text-danger">*</span></b-td>
                                        <b-td>
                                            <b-row>
                                                <b-col sm="10">
                                                    <input type="text" :class="{ 'form-control': true, 'is-invalid': form.errors.has('nama')}" v-model="form.nama" placeholder="Masukkan Bentuk Badan" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="form.errors.has('nama')" class="form-text text-danger">{{errors.nama[0]}}</em>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        <hr>
                        <div class="float-right">
                            <span>
                                <b-button variant="primary" type="submit" squared class="px-4" pill><i class="fa fa-save"></i> Simpan</b-button>
                            </span>
                            <span class="px-2">
                                <b-button variant="secondary" squared class="px-4" @click="reset" pill><i class="fa fa-redo-alt"></i> Reset</b-button>
                            </span>
                        </div>
                    </b-form>
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
	</div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import Logo from '@/assets/images/logoSumut.png'
import HeadTitle from '@/components/header/HRetribusi.vue'

export default {
    components: {
        HeadTitle
    },
    data () {
        return {
            imageLogo: Logo,
            form: new Form({
                no_urut: '',
                nama: ''
            }),

           /*  // variabel form rekening
            rekening: {
                kodeNama: '',
                kodeRekening: '0'
            }, */
            errors: [],
            message: null,
            // csrf token
            // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    },
    methods: {
        // store data
        store () {
            this.form.post('/api/wajib-pajak/status-registrasi').then(response => {
                if (this.form.successful) {
                    this.$swal({
                        icon: 'success',
                        title: 'Data berhasil ditambah',
                        type: 'success',
                        confirmButtonClass: 'btn btn-info',
                        showConfirmButton: false,
                        showCloseButton: true,
                        timer: 1500
                    })
                    this.$router.push({name: 'ListStatusRegistrasi'})
                    /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors
                    this.message = error.response.data.message
                } else if (error.response.status === 401) {
                    localStorage.removeItem("EP_token");
                    this.$router.push({name: 'Login'})
                }
                console.log(error)
            })
        },

        // fungsi untuk membuat kode rekening
        changeKodeRekening (code) {
            if (this.rekening.kodeNama == '') {
                this.rekening.kodeRekening = '0'
                this.form.kode = ''
            } else {
                this.form.kode = code
            }
        },
        
        // reset
        reset () {
            this.form.reset()
        }
    }
}
</script>